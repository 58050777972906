import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import cn from "classnames"
import InfoModule from "../components/InfoModule/index"
import INFRASTRUCTURE_IMAGE from "../images/infrastructure-desktop.svg"
import GOALS_IMAGE from "../images/goals-desktop.svg"
import DELIVERY_IMAGE from "../images/delivery-desktop.svg"
import IMPACT_LOGO from "../images/impact-logo.svg"
import FASTER_LOGO from "../images/faster-logo.svg"
import SIMPLE_LOGO from "../images/simple-logo.svg"
import Data from "../data/data"
import style from "./chartering-teams.module.css"

const CharteringTeams = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{Data.charteringTeams.title}</title>
          <meta
            name="description"
            content={Data.metaData.description.charteringTeams}
          />
          <link
            href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <div className={style.root}>
          <div className={style.charteringBanner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.charteringBannerContent}>
                    <h2 className={style.charteringTitle}>
                      {Data.charteringTeams.pageTitle}
                    </h2>
                    <h1 className={style.charteringHeading}>
                      {Data.charteringTeams.heading}
                    </h1>
                    <div className="row">
                      <div className="col s12">
                        <p className={style.charteringSubHeading}>
                          {Data.charteringTeams.subHeading}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Strategic Infrastructure Section */}
          <div className={style.charteringStrategicSection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.charteringModule}>
                    <InfoModule
                      image={INFRASTRUCTURE_IMAGE}
                      imageAlt={"Infrastructure image"}
                      heading={Data.charteringTeams.strategicSectionHeading}
                      headingLarge={true}
                      rowOneText={
                        Data.charteringTeams.strategicSectionParagraphOne
                      }
                      rowTwoText={
                        Data.charteringTeams.strategicSectionParagraphTwo
                      }
                      rowThreeText={
                        Data.charteringTeams.strategicSectionParagraphThree
                      }
                      reverseContentPosition={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Achieving Goals Section */}
          <div className={style.charteringGoalsSection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.charteringModule}>
                    <InfoModule
                      image={GOALS_IMAGE}
                      imageAlt={"Goals image"}
                      heading={Data.charteringTeams.goalsSectionHeading}
                      headingLarge={true}
                      rowOneText={Data.charteringTeams.goalsSectionParagraphOne}
                      rowTwoText={Data.charteringTeams.goalsSectionParagraphTwo}
                      rowThreeText={
                        Data.charteringTeams.goalsSectionParagraphThree
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Delivery Section */}
          <div className={style.charteringDeliverySection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <div className={style.charteringModule}>
                    <InfoModule
                      image={DELIVERY_IMAGE}
                      imageAlt={"Delivery image"}
                      heading={Data.charteringTeams.deliverySectionHeading}
                      headingLarge={true}
                      rowOneText={
                        Data.charteringTeams.deliverySectionParagraphOne
                      }
                      rowTwoText={
                        Data.charteringTeams.deliverySectionParagraphTwo
                      }
                      rowThreeText={
                        Data.charteringTeams.deliverySectionParagraphThree
                      }
                      reverseContentPosition={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Benefits Section */}
          <div className={style.charteringBenefitsSection}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <h4 className={style.charteringBenefitsHeading}>
                    {Data.charteringTeams.benefitsSectionHeading}{" "}
                  </h4>
                  <div className={style.charteringBenefits}>
                    <div className={style.charteringBenefitsModule}>
                      <img
                        className={cn(
                          style.charteringBenefitsImg,
                          style.impact
                        )}
                        src={IMPACT_LOGO}
                        alt="Impact Logo"
                      />
                      <div className={style.charteringBenefitsTextWrap}>
                        <p className={style.charteringBenefitsText}>
                          {Data.charteringTeams.benefitsModuleOneText}
                        </p>
                      </div>
                    </div>

                    <div className={style.charteringBenefitsModule}>
                      <img
                        className={cn(
                          style.charteringBenefitsImg,
                          style.impact
                        )}
                        src={FASTER_LOGO}
                        alt="Faster Logo"
                      />
                      <div className={style.charteringBenefitsTextWrap}>
                        <p className={style.charteringBenefitsText}>
                          {Data.charteringTeams.benefitsModuleTwoText}
                        </p>
                      </div>
                    </div>

                    <div className={style.charteringBenefitsModule}>
                      <img
                        className={cn(
                          style.charteringBenefitsImg,
                          style.impact
                        )}
                        src={SIMPLE_LOGO}
                        alt="Simple Logo"
                      />
                      <div className={style.charteringBenefitsTextWrap}>
                        <p className={style.charteringBenefitsText}>
                          {Data.charteringTeams.benefitsModuleThreeText}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

CharteringTeams.propTypes = {}

export default CharteringTeams
